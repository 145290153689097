.packages {
  background-image: url(../Images/Mr.Olive/backgrounds/_KH13235.jpg);
  background-size: cover;
  background-position: center center;
  height: 70vh;
  background-repeat: no-repeat;
}
.package {
  background-image: url(../Images/Mr.Olive/packages/_KH1314555.jpeg);
  background-size: cover;
  /* background-position: center; */
  width: 100% !important;
  height: 25vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
  /* border-radius: 50% */
}
.contactMe {
  background-image: url(../Images/Group-of-customer-service-representatives.jpg);
  background-size: cover;
  background-position: center;
  width: 30% !important;
  height: 30vh;
}
/* i{
    background-color: white;
    border-radius: 50%;
    border: 1px solid grey;
  position: absolute;
  top:'25px'
} */
.swiper {
  width: 100%;
  z-index: 0;
}
