/* .Logo {
    background-image: url(./logo.png);
  } */
/* .mapFooter {
  width: 100%;
  height: 250px;
  border: 0;
} */
.footer-links {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
}

.footer-links li {
  flex: 1 1 calc(50% - 100px);
  text-align: center;
}


