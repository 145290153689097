.aboutUs {
  width: 100% !important;
  /* height: 100px!important; */
  background-image: url(../Images/Mr.Olive/backgrounds/mroliveFac.jpg);

  height: 70vh;
  background-size: cover !important;
  background-position: center center;
  /* background-size: auto;
    background-repeat: repeat-x; */
}
.oliveOilAbout {
  width: 100% !important;
  background-size: cover;
  background-position: center center;
  /* height: 30vh; */
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226 223 223);
}
.contactMeFooter {
  background-size: cover;
  background-position: center center;
  width: 100% !important;
  height: 23vh;
}
.video-react-controls-enabled{
  padding: 0 !important;
}
.video-react-video{
  width: 100% !important;
}
