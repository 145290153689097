.contact {
  width: 100% !important;
  background-image: url(../Images/Mr.Olive/backgrounds/contact\ us\ cover2.jpg);
  background-size: cover;
  background-position: center center;
  height: 70vh;
  /* background-size: auto;
    background-repeat: repeat-x; */
}
.contactUs {
  width: 100% !important;
  background-image: url(../Images/Group-of-customer-service-representatives.jpg);
  background-size: cover;
  background-position: center;
  height: 30vh;
  margin-bottom: 2%;
}
.map {
  width: 100%;
  height: 350px;
  border: 0;
}
.send {
  background-color: #4e732c !important;
  color: white !important;
  width: 90% !important;
  border-radius: 20px !important;
}
.buttonContainer1 {
  display: flex;
  justify-content: center;
}
.form-control {
  border-radius: 20px !important;
}
.space {
  padding-top: 10%;
  padding-bottom: 10%;
  text-align: center;
  font-weight: bold;
}
