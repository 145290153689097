.homeContent {
  box-shadow: 5px -10px 15px -5px rgba(255, 255, 255, 0.1);

  background: #4e732c;
  border-radius: 15px;
  transition: all 3s ease;
}

.homeContent:hover {
  border-radius: 20px;

  /* transition: opacity 2s ease; */
  opacity: 0.7;
  transition: opacity 3s ease-in;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}
.circular {
  background-color: #a9a9b3;
  border-radius: 70px;
  /* padding: 5px; */
  box-shadow: 3px 3px gray;
  margin-bottom: 15px;
  background-color: #cacad1;
  padding: 0;
}
.circular:hover {
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
  transition: all 1s ease;
}
.settings-close {
  width: 100% !important;
  /* height: 100px!important; */
  background-image: url(../Images/Mr.Olive/olive-background.jpg);
  background-size: cover !important;
  background-position: center;
  height: 70vh;
  /* background-size: auto; */
  /* background-repeat: repeat-x; */
}
.olive {
  background-image: url(../Images/Mr.Olive/packages/_KH1314555.jpeg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 30vh;
}
.olive:hover {
  width: 100% !important;
  height: 32vh;
  transition: all 2s ease;
}
.olive1 {
  background-image: url(../Images/Mr.Olive/GreenOlive/زيتون\ أخضر\ محشي\ فلفل\ أحمر.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 10vh;
  border-radius: 50%;
}
.olive2 {
  background-image: url(../Images/Mr.Olive/backgrounds/_KH13235.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 10vh;
  border-radius: 50%;
}
.olive3 {
  background-image: url(../Images/Mr.Olive/photoGalleryHome.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 10vh;
  border-radius: 50%;
}
.contactMe {
  background-image: url(../Images/Group-of-customer-service-representatives.jpg);
  background-size: cover;
  background-position: center;
  width: 30% !important;
  height: 30vh;
}

@media only screen and (max-width: 600px) {
  .bgFirst {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .bgSecond {
    width: 25% !important;
  }
  .olive1 {
    width: 100% !important;
  }
  .olive3 {
    width: 100% !important;
  }
  .olive2 {
    width: 100% !important;
  }
  .bgThird {
    width: 75% !important;
    display: flex !important;
  }
}
/*-----------------------The Swiper--------------------------*/
.swiperHome {
  width: 70% !important;
  z-index: 0;
}
@media only screen and (max-width: 600px) {
  .swiperHome {
    width: 100% !important;
    z-index: 0;
  }
}
.swiper-button-prev {
  color: rgb(56, 124, 48) !important;
}
.swiper-button-next {
  color: rgb(56, 124, 48) !important;
}
.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.dot {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #b3d4fc;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: rgb(56, 124, 48);
    box-shadow: rgb(56, 124, 48);
  }

  50% {
    transform: scale(1.2);
    background-color: rgb(56, 124, 48);
    box-shadow: rgb(56, 124, 48);
  }

  100% {
    transform: scale(0.8);
    background-color: rgb(56, 124, 48);
    box-shadow: rgb(56, 124, 48);
  }
}
