.scroll-top {
    position: fixed;
    right: 0;
    bottom: 0;
    margin-right: 1rem;
    padding: 1rem;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 1000; /* Ensure it appears above other content */
  }
  
  .scroll-top:nth-child(2) {
    margin-bottom: 6rem; /* Adjust to create spacing between buttons */
  }

  
