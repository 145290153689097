.marc {
  width: 200px !important;
  /* height: 100px!important; */
  background-image: url(../Images/Logo.png);
  /* background-size: cover; */
  background-position: center;
  height: 100px;
  background-size: auto;
  /* background-repeat: repeat-x; */
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: #4e732c;
  /* padding: 14px 16px; */
  /* background-color: inherit; */
  font-family: inherit;
  margin: 0;
}

/* .navbar a:hover, .dropdown:hover .dropbtn {
    background-color: red;
  } */

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/*-----------------------------------------------------------*/
.dropbtn2 {
  background-color: #f8f9fa;
  color: #4e732c;
  /* padding: 16px; */
  font-size: 16px;
  border: none;
}

.dropdown2 {
  position: relative;
  display: inline-block;
}

.dropdown-content2 {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content2 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content2 a:hover {
  background-color: #ddd;
}

.dropdown2:hover .dropdown-content2 {
  display: block;
}



.accordionBg:hover {
  background-color: #fff !important;
  color: #4e732c;
}

.logoOlive {
  background-size: cover;
  background-position: center;
  width: 50% !important;
}

.nav-item:hover {
  background-color: rgba(53, 75, 32, 0.8);
  border-radius: 50px;
}

.dropdown-hover:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}
