.products {
  width: 100% !important;
  /* height: 100px!important; */
  background-image: url(../Images/Mr.Olive/backgrounds/different-kinds-olives-olive-oil-clay-white-bowls-with-olive-leaves-flat-lay-white-wood.jpg);
  background-size: cover;
  background-position: center;
  height: 70vh;
  /* background-size: auto;
   background-repeat: repeat-x; */
}
.Slices {
  background-image: url(../Images/Mr.Olive/GreenOlive/زيتون\ أخضر\ شرائح\ عجيزي.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
  /* border-radius: 50% */
}
.pickled {
  background-image: url(../Images/Mr.Olive/GreenOlive/زيتون\ أخضر\ دولسي.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.pitted {
  background-image: url(../Images/Mr.Olive/GreenOlive/زيتون\ أخضر\ بيكوال\ مخلي.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.stuffed {
  background-image: url(../Images/Mr.Olive/GreenOlive/زيتون\ أخضر\ محشي\ فلفل\ أحمر.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.contactMe {
  background-image: url(../Images/Group-of-customer-service-representatives.jpg);
  background-size: cover;
  background-position: center;
  width: 30% !important;
  height: 30vh;
}
/* i{
    background-color: white;
    border-radius: 50%;
    border: 1px solid grey;
  position: absolute;
  top:'25px'
} */
.blackOlive {
  background-image: url(../Images/Mr.Olive/BlackOlive/زيتون\ أسود\ مؤكسد\ سليم.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.mix {
  background-image: url(../Images/Mr.Olive/mix/مخلل\ مشكل.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.slicesBlackOlive {
  background-image: url(../Images/Mr.Olive/BlackOlive/زيتون\ أسود\ مؤكسد\ شرائح.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.pickledBlackOlive {
  background-image: url(../Images/Mr.Olive/BlackOlive/زيتون\ بيكوال\ أسود\ طبيعي\ سليم.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.pittedBlackOlive {
  background-image: url(../Images/Mr.Olive/BlackOlive/زيتون\ بيكوال\ أسود\ طبيعي\ مخلي.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.redJalapeno {
  background-image: url(../Images/Mr.Olive/jalapeno/فلفل\ شيري\ سليم.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.greenJalapeno {
  background-image: url(../Images/Mr.Olive/jalapeno/فلفل\ لمباردي.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.oliveOilProducts {
  background-image: url(../Images/Mr.Olive/jalapeno/فلفل\ لمباردي.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.ARTICHOKE {
  background-image: url(../Images/Mr.Olive/mix/خرشوف\ قرص.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.LEMON {
  background-image: url(../Images/Mr.Olive/mix/ليمون\ أصفر.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.CAULIFLOWER {
  background-image: url(../Images/Mr.Olive/mix/قرنبيط.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.crushedJalapeno {
  background-image: url(../Images/Mr.Olive/jalapeno/هريسة\ 3.png);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.greenOlive {
  background-image: url(../Images/Mr.Olive/GreenOlive/زيتون\ أخضر\ بيكوال\ سليم.jpg);
  background-size: cover;
  background-position: center;
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.greenOlive:hover {
  height: 33vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}
.Slices:hover {
  height: 33vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}
.pickled:hover {
  height: 33vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}
.pitted:hover {
  height: 33vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}
.stuffed:hover {
  height: 33vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}

.crushedJalapeno:hover {
  height: 33vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}
.packagaya:hover {
  height: 37vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}
.CAULIFLOWER:hover {
  height: 33vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}

.LEMON:hover {
  height: 33vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}

.ARTICHOKE:hover {
  height: 33vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}

.greenJalapeno:hover {
  height: 33vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}

.redJalapeno:hover {
  height: 33vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}
.pittedBlackOlive:hover {
  height: 33vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}
.pickledBlackOlive:hover {
  height: 33vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}
.slicesBlackOlive:hover {
  height: 33vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}
.blackOlive:hover {
  height: 33vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}
.oliveOilProducts:hover {
  height: 33vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}
.mix:hover {
  height: 33vh;
  transition: all 2s ease;
  box-shadow: 0 0 0 1px #4e732c, 0 30px 30px rgb(0 0 0 / 7%),
    0 15px 15px rgb(0 0 0 / 6%), 0 10px 8px rgb(0 0 0 / 5%),
    0 4px 4px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 3%);
}
.greenOlive2 {
  background-image: url(../Images/Capture4.PNG);
  background-size: cover;
  /* background-position: center; */
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.greenOlive3 {
  background-image: url(../Images/Capture4.PNG);
  background-size: cover;
  /* background-position: center; */
  width: 100% !important;
  height: 32vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.photo {
  background-size: cover;
  width: 100% !important;
  margin: 5px;
  height: 34vh;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.packagaya {
  background-size: cover;
  width: 100% !important;
  margin: 5px;
  height: 35vh;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
/*----------------------------------------------------------------*/
.photoContainer2 {
  /* background-color: tomato; */
  cursor: pointer;
}
#close {
  position: absolute;
  right: 10px;
  top: 10px;
}
.buttonContainer2 {
  width: 40px;
  height: 40px;
  background-color: white;
  color: black;
  border-radius: 50%;
}

.lightContainer2 {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 1500px;
  background-color: #000;
  z-index: 101;
  overflow-y: scroll;
}
.lightBox2 {
  /* background-image:url(../Images/slide14.jpg) ; */
  background-position: center center;
  background-size: cover;
  /* background-color: #4e732c; */
  width: 100%;
  height: 100%;
  position: relative;
}
.lightBox2 i {
  font-size: 1.2rem;
  /* margin: 10px; */
  cursor: pointer;
}
.get {
  background-color: #4e732c !important;
  color: white !important;
  width: 50% !important;
  border-radius: 20px !important;
}
.ayhaga {
  color: #fff;
}
.very {
  background-size: cover;
  background-position: center;
  width: 80% !important;
  height: 25vh;
  margin: 5px;

  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(0, 0, 0, 0.2);
}

@media only screen and (max-width: 600px) {
  .lightContainer2 {
    right: 0;
  }
  .ayhaga {
    background-color: #000;
    border-radius: 20px;
    padding-top: 20px;
    color: #fff;
  }
  .very {
    height: 26vh;
  }
  .packagaya {
    height: 35vh;
  }
}

@media only screen and (min-width: 768px) {
  .lightContainer2 {
    right: 0px;
  }
}
@media only screen and (min-width: 992px) {
  .lightContainer2 {
    right: 0px;
  }
}
@media only screen and (min-width: 1200px) {
  .lightContainer2 {
    right: 800px;
  }
}
@media only screen and (min-width: 1400px) {
  .lightContainer2 {
    right: 1000px;
  }
}
@media only screen and (min-width: 1600px) {
  .lightContainer2 {
    right: 1500px;
  }
}
