.gallery {
  width: 100% !important;
  /* height: 100px!important; */
  background-image: url(../Images/Mr.Olive/backgrounds/bowls-with-black-green-olives-olive-oil-gray-background.jpg);
  background-size: cover;
  background-position: center;
  height: 70vh;
  /* background-size: auto;
    background-repeat: repeat-x; */
}
.specificPhoto {
  /* background-image: url(../../public/Images/slide12.jpg); */
  background-size: cover;
  /* background-position: center; */
  width: 100% !important;
  height: 25vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
  /* border-radius: 50% */
}
.photo1 {
  /* background-image: url(../../public/Images/slide13.jpg); */
  background-size: cover;
  /* background-position: center; */
  width: 100% !important;
  height: 25vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.photo2 {
  /* background-image: url(../../public/Images/slide14.jpg); */
  background-size: cover;
  /* background-position: center; */
  width: 100% !important;
  height: 25vh;
  margin: 5px;
  border: #4e732c solid 2px;
  border-radius: 20px;
  box-shadow: 3px 3px rgb(226, 223, 223);
}
.photoContainer {
  /* background-color: tomato; */
  cursor: pointer;
}
.lightContainer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #0006;
}
.lightBox {
  /* background-image:url(../Images/slide14.jpg) ; */
  background-position: center center;
  background-size: cover;
  /* background-color: #4e732c; */
  width: 63%;
  height: 70%;
  position: relative;
}
.lightBox i {
  font-size: 1.2rem;
  margin: 10px;
  cursor: pointer;
}

.contactMe {
  background-image: url(../Images/Group-of-customer-service-representatives.jpg);
  background-size: cover;
  background-position: center;
  width: 30% !important;
  height: 30vh;
}
/* i{
    background-color: white;
    border-radius: 50%;
    border: 1px solid grey;
  position: absolute;
  top:'25px'
} */
#close {
  position: absolute;
  right: 10px;
  top: 10px;
}
.buttonContainer {
  width: 40px;
  height: 40px;
  background-color: white;
  color: black;
  border-radius: 50%;
}

@media only screen and (max-width: 600px) {
  .lightBox {
    width: 100%;
    height: 28%;
  }
}
